// Takes an Array of Objects from the alchemy Picture image srcset settings and returns an object per Format.
// [{
//   type: FORMAT
//   srcsetstring: "picture.format desc, picture.format desc"
//  }...]

import useImageFormats from "~/composables/useImageFormats"

const { createFormatArray: cfa } = useImageFormats()

export default {
  methods: {
    createFormatArray(srcSet: ImageSource[]) {
      return cfa(srcSet)
    },
  },
}
